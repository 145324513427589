<template>
    <div :class="cssClass">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        hasPadding: {
            default: true,
        },
    },
    computed: {
        cssClass() {
            return {
                'settings-label': true,
                'settings-label--padding': this.hasPadding,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.settings-label {
    color: var(--Gray-700, #344054);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &--padding {
        padding-bottom: 6px;
    }
}
</style>
