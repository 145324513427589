<template>
    <div class="heading">
        <h2 class="heading__title">{{ title }}</h2>
        <div class="heading__description">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.heading {
    &__title {
        padding-bottom: 2px;
    }

    &__description {
        font-size: 16px;
        font-weight: 500;
        color: #475467;

        & :last-of-type {
            margin-bottom: 0;
        }
    }
}
</style>
