import store from '../store/index.js';

import defaultHeaders from '@/configs/defaultHeaders.json';
import { applyFilters } from './createFilterFields.js';
import generateContactDataFromProfile from './generateContactDataFromProfile.js';

export function getFlatLeads(table, rawLeads) {
    const leads = rawLeads;
    let flatLeads = [];
    for (const lead of leads) {
        const contactData = generateContactDataFromProfile(table, lead);
        const leadData = {
            status: lead.status || 'Undefined',
            email: contactData.email || 'Undefined',
            name: contactData.name || 'Undefined',
            phone: contactData.phone || 'Undefined',
            createdAt: lead.createdAt,
            lastChange: lead.history.length > 0 ? lead.history[lead.history.length - 1].date : 'Undefined',
            changes: lead.history.length,
            id: lead._id,
            source: lead.source || 'Undefined',
            amountFilesUploaded: lead.amountFilesUploaded || 0,
            whatsapp_id: lead.whatsapp_id,
            dsgvoStatus: lead.dsgvoStatus,
            ownerTable: lead.ownerTable,
        };
        for (const item in lead.funnelData.profile) {
            const data = lead.funnelData.profile[item];
            const title = data.title === 'status' ? 'altstatus' : data.title;
            leadData[title] = data.value;
        }

        flatLeads.push(leadData);
    }
    return flatLeads;
}

export function getTranslatedSortedLeads(table, rawLeads) {
    const leads = getFlatLeads(table, rawLeads);
    const headers = table.tableAndProfileConfig || defaultHeaders;
    const translatedLeads = leads.map((lead) => {
        //for every lead we need to translate the funnelData to the table headers
        const translatedLead = {};
        for (const header of headers) {
            const questions = header.questions;
            const name = header.name;
            for (const question of questions) {
                if (lead[question]) {
                    if (translatedLead[name]) {
                        //append to existing value
                        translatedLead[name] = translatedLead[name] + ', ' + lead[question];
                    } else {
                        translatedLead[name] = lead[question];
                    }
                }
            }
            if (questions.length === 0) {
                translatedLead[name] = lead[name] || '';
            }
        }
        translatedLead['_id'] = lead.id;
        translatedLead['_email'] = lead.email;
        translatedLead['_name'] = lead.name;
        translatedLead['_phone'] = lead.phone;
        translatedLead['_createdAt'] = lead.createdAt;
        translatedLead['_lastChange'] = lead.lastChange;
        translatedLead['_changes'] = lead.changes;
        translatedLead['_status'] = lead.status;
        translatedLead['_profile'] = lead;
        translatedLead['_amountFilesUploaded'] = lead.amountFilesUploaded;
        translatedLead['_ownerTable'] = lead.ownerTable;
        translatedLead['_whatsapp_id'] = lead.whatsapp_id;
        translatedLead['_dsgvoStatus'] = lead.dsgvoStatus;
        return translatedLead;
    });
    return translatedLeads;
}

export function getFilteredTranslatedSortedLeads(table, rawLeads) {
    const activeFilters = store.getters['customer/getActiveFilters'];
    const activeStatus = store.getters['customer/getActiveStatus'];
    const afterStatusFilter = rawLeads.filter((lead) => {
        if (!activeStatus || activeStatus.length === 0) {
            return true;
        } else {
            return activeStatus.includes(lead.status);
        }
    });
    const isAgency = store.getters.isAgency();
    const afterStatusAndPrequalFilter = afterStatusFilter.filter((lead) => {
        if (isAgency) {
            return true;
        } else {
            return !(table.prequalify && lead.status === 'Unbearbeitet');
        }
    });

    const leadsAfterCustomFilters = applyFilters(afterStatusAndPrequalFilter, activeFilters);
    const leads = getTranslatedSortedLeads(table, leadsAfterCustomFilters);
    return leads;
}
export function getAllAvailableQuestions(table, rawLeads) {
    const leads = getFlatLeads(table, rawLeads);
    const questions = [];
    for (const lead of leads) {
        for (const key in lead) {
            if (!questions.includes(key)) {
                questions.push(key);
            }
        }
    }
    //filter out phone,email and name
    const filteredQuestions = questions.filter((question) => {
        return question !== 'phone' && question !== 'email' && question !== 'name';
    });
    return filteredQuestions;
}
