<template>
    <div :class="cssClass">
        <label v-if="label">{{ label }}</label>

        <v-tooltip open-on-hover top center transition="slide-y-reverse-transition" :max-width="maxWidth">
            <template v-slot:activator="{ on, attrs }">
                <div class="instructions__activator">
                    <v-icon :size="triggerIconSize" v-bind="attrs" v-on="on" class="instructions__activator-icon">
                        $helpCircle
                    </v-icon>
                </div>
            </template>

            <div class="instructions__body">
                <div class="instructions__header">
                    <v-icon v-if="icon" class="instructions__icon">{{ icon }}</v-icon>
                    <h2 class="instructions__title">{{ title }}</h2>
                    <v-icon class="instructions__help-icon" size="24">$helpCircle</v-icon>
                </div>

                <slot></slot>
            </div>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: false,
        },
        triggerIconSize: {
            type: Number,
            default: 18,
        },
        icon: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        modifier: {
            type: String,
            required: false,
        },
        maxWidth: {
            type: Number,
            default: 350,
        },
    },
    computed: {
        cssClass() {
            return {
                instructions: true,
                [`instructions--${this.modifier}`]: !!this.modifier,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.instructions {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    padding-bottom: 2px;

    &__activator-icon {
        cursor: pointer;
        color: #344054;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 4px;
        line-height: 1.5;
    }

    &__icon {
        color: inherit;
        color: #344054;
    }

    &__title {
        flex-grow: 1;
        margin-bottom: 0;
    }

    &__help-icon {
        padding-bottom: 4px;
        color: #344054;
    }

    &__body {
        position: relative;
        padding: 10px 6px;

        // arrow
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(calc(-50% - 3px));
            bottom: -19px;
            width: 12px;
            height: 12px;
            background-color: white;
            border: 1px solid transparent;
            border-right-color: #eaecf0;
            border-bottom-color: #eaecf0;
            transform-origin: center;
            rotate: 45deg;
        }
    }
}

// copy styling
:deep(p) {
    padding-bottom: 8px;
    color: #475467;
    font-size: 14px !important;
    line-height: 1.7;

    &:last-child {
        margin-bottom: 0;
    }
}

:deep(h2) {
    margin-bottom: 6px;
}
</style>
