<template>
    <v-card flat :class="cssClass" @click="$emit('click')">
        <div class="thumbnail-card__preview-container">
            <slot></slot>
        </div>

        <div class="thumbnail-card__body">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" v-if="icon" class="thumbnail-card__icon" size="20">
                        {{ icon }}
                    </v-icon>
                </template>

                <span>{{ iconTooltipText }}</span>
            </v-tooltip>

            <div class="thumbnail-card__title">{{ title || '' }}</div>

            <div v-if="$slots.actions" class="thumbnail-card__actions">
                <slot name="actions"></slot>
            </div>
        </div>

        <div v-if="$slots.footer" class="thumbnail-card__footer">
            <slot name="footer"></slot>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: false,
        },
        iconTooltipText: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        titleOneLiner: {
            type: Boolean,
            default: false,
        },
        modifier: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        cssClass() {
            return {
                'thumbnail-card': true,
                [`thumbnail-card--${this.modifier}`]: !!this.modifier,
                'thumbnail-card--disabled': this.disabled,
                'thumbnail-card--one-liner': this.titleOneLiner,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.thumbnail-card {
    $self: &;

    background-color: white;
    border: 1px solid #eaecf0;
    border-radius: 12px;
    box-shadow: $elevation2;
    overflow: hidden;

    &__preview-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 150px;
        background-color: #f2f4f7;
        overflow: hidden;
    }

    &__body {
        display: flex;
        align-items: center;
        gap: 14px;
        padding: 16px 24px;
        min-height: 68px;
    }

    &__icon {
        color: inherit;
    }

    &__title {
        flex-grow: 1;
        font-size: 16px;
        font-weight: 600;
    }

    &__title-icon-pre {
    }

    &__actions {
        margin-right: -8px;
    }

    &__footer {
        padding: 16px 24px;
        border-top: 1px solid #eaecf0;
    }

    &:hover:not(&--disabled) {
        box-shadow: $elevation4;
    }

    // preset modifiers
    &--one-liner {
        #{ $self }__title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    // modifiers
    &--widget-preview {
        &#{ $self }--disabled {
            opacity: 0.75;
            cursor: not-allowed;
        }
    }

    &--whatsapp {
        display: flex;
        flex-direction: column;

        #{ $self }__preview-container {
            flex-grow: 1;
            align-items: flex-start;
            max-height: 300px;
        }
    }
}
</style>
