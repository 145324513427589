<template>
    <div :class="cssClass">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'badge', // badge | pill
        },
        size: {
            type: String,
            default: 'medium', // small | medium | large
        },
        color: {
            type: String,
            default: 'green',
        },
    },
    computed: {
        cssClass() {
            return {
                badge: true,
                [`badge--${this.type}`]: !!this.type,
                [`badge--${this.size}`]: !!this.size,
                [`badge--${this.color}`]: !!this.color,
            };
        },
    },
};
</script>

<style scoped lang="scss">
.badge {
    display: inline-flex;
    line-height: 1;

    // types
    &--badge {
        border-radius: 6px;
    }

    &--pill {
        border-radius: 999px;
    }

    // sizes
    &--small {
        font-size: 10px;
        padding: 4px 8px;
    }

    &--medium {
        font-size: 12px;
        padding: 6px 10px;
    }

    &--large {
        font-size: 12px;
        padding: 8px 12px;
    }

    // colors
    &--gray {
        color: #344054;
        border: 1px solid var(--Gray-200, #eaecf0);
        background: var(--Gray-50, #f9fafb);
    }

    &--purple {
        color: #573bc5;
        border: 1px solid var(--Primary-200, #d9d6fe);
        background: var(--Primary-50, #f4f3ff);
    }

    &--red {
        color: #b42318;
        border: 1px solid var(--Error-200, #fecdca);
        background: var(--Error-50, #fef3f2);
    }

    &--yellow {
        color: #b54708;
        border: 1px solid var(--Warning-200, #fedf89);
        background: var(--Warning-50, #fffaeb);
    }

    &--green {
        color: #067647;
        border: 1px solid var(--Success-200, #abefc6);
        background: var(--Success-50, #ecfdf3);
    }

    &--green {
        color: #067647;
        border: 1px solid var(--Success-200, #abefc6);
        background: var(--Success-50, #ecfdf3);
    }
}
</style>
