<template>
    <media-controller ref="mediaController" :class="cssClass">
        <!-- providers -->
        <video v-if="provider === 'html5'" slot="media" :src="src"></video>
        <youtube-video
            v-if="provider === 'youtube'"
            :poster="thumbnail"
            class="video-player__youtube"
            :src="src"
            slot="media"
            crossorigin
        ></youtube-video>
        <wistia-video v-if="provider === 'wistia'" :src="src" slot="media" crossorigin></wistia-video>
        <vimeo-video v-if="provider === 'vimeo'" :src="src" slot="media" crossorigin></vimeo-video>

        <!-- controlls -->
        <media-control-bar class="video-player__controls">
            <media-play-button class="video-player__play"></media-play-button>

            <div class="video-player__volume-controls">
                <media-mute-button class="video-player__volume-mute"></media-mute-button>
                <media-volume-range class="video-player__volume-range"></media-volume-range>
            </div>

            <media-time-range class="video-player__timeline"></media-time-range>

            <media-fullscreen-button class="video-player__fullscreen"></media-fullscreen-button>
        </media-control-bar>

        <!-- other -->
        <media-loading-indicator slot="centered-chrome" noautohide></media-loading-indicator>
        <media-poster-image class="video-player__thumbnail" slot="poster" :src="thumbnail"></media-poster-image>
    </media-controller>
</template>

<script>
//
// media-chrome doc's
// https://www.media-chrome.org/docs/en/components/media-airplay-button
//

export default {
    props: {
        provider: {
            type: String,
            default: 'html5',
        },
        src: {
            type: String,
            required: true,
        },
        thumbnail: {
            type: String,
            required: false,
        },
        modifier: {
            type: String,
            required: false,
        },
    },
    computed: {
        cssClass() {
            return {
                'video-player': true,
                [`video-player--${this.provider}`]: true,
                [`video-player--${this.modifier}`]: !!this.modifier,
            };
        },
    },
    methods: {
        play() {
            this.$refs.mediaController.media.play();
            console.log('playerssss: ', this.$refs);
        },
        pause() {
            this.$refs.mediaController.media.pause();
        },
    },
};
</script>

<style lang="scss" scoped>
$controlls-border-radius: 6px;

@mixin controllButton {
    padding: 5px;
    &:hover {
        // background-color: #ccc;
    }
}

.video-player {
    $self: &;

    // button
    --media-button-icon-width: 30px;
    --media-button-icon-height: 20px;

    // range track
    --media-range-track-height: 8px;
    --media-range-track-border-radius: 999px;
    --media-range-track-background: #e8e9eb;

    // range thumb
    --media-range-thumb-width: 16px;
    --media-range-thumb-height: 16px;
    --media-range-thumb-background: white;
    --media-range-thumb-border: 2px solid var(--v-primary-base);
    --media-range-bar-color: var(--v-primary-base);
    --media-time-range-buffered-color: #d0d5dd;

    // tooltip
    --media-tooltip-display: none;

    // other
    // --media-primary-color: var(--v-primary-base);
    --media-icon-color: #667085;
    --media-control-background: transparent;
    --media-control-hover-background: transparent;
    // --media-control-height: 30px;

    // css
    width: 100%;

    &__youtube {
    }

    &__thumbnail {
        object-fit: fill;
    }

    // controlls
    &__controls {
        position: absolute;
        bottom: 16px;
        left: 16px;
        right: 16px;
        border-radius: $controlls-border-radius;
        background-color: white;
    }

    &__play {
        @include controllButton;
    }

    &__volume-controls {
        @include controllButton;

        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        flex-shrink: 0;
        transition: 0.2s;

        &:hover {
            #{ $self }__volume-range {
                opacity: 1;
            }
        }
    }

    &__volume-mute {
        flex-shrink: 0;
        transition: 0.2s;
    }

    &__volume-range {
        opacity: 0;

        position: absolute;
        top: 0;
        left: 2px;
        width: 100px;
        padding-right: 16px;
        margin-left: -5px;
        margin-top: 5px;
        background-color: white;
        border-radius: $controlls-border-radius;
        rotate: -90deg;
        transform-origin: top left;
        transition: 0.2s;
    }

    &__timeline {
        flex-grow: 1;
        transition: 0.2s;
    }

    &__fullscreen {
        @include controllButton;
    }
}
</style>
