<template>
    <div :class="cssClass">
        <div class="divider__line"></div>
        <SettingsLabel v-if="text" class="divider__text">{{ text }}</SettingsLabel>
        <div v-if="text" class="divider__line"></div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: false,
        },
        padding: {
            type: String, // small | medium | large
            required: false,
        }
    },
    computed: {
        cssClass() {
            return {
                'divider': true,
                [`divider--padding-${this.padding}`]: this.padding,
            }
        }
    },
}
</script>

<style scoped lang="scss">
.divider {
    $self: &;

    display: flex;
    align-items: center;
    gap: 16px;

    &__line {
        flex: 1;
        height: 1px;
        background-color: #EAECF0;
    }

    &__text {}

    &--padding {
        &-small {
            padding: 5px 0;
        }

        &-medium {
            padding: 10px 0;
        }

        &-large {
            padding: 15px 0;
        }
    }
}
</style>
