<template>
    <v-btn v-bind="$attrs" v-on="$listeners" dense outlined class="icon-button">
        <v-icon class="icon-button__icon" size="20">
            <slot></slot>
        </v-icon>
    </v-btn>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.icon-button {
    background-color: white !important;
    border: 1px solid #eaecf0 !important;
    padding: 2px !important;
    min-width: unset !important;
    aspect-ratio: 1/1;
    min-height: unset !important;
    height: 40px !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
</style>
