// store.js

const state = {
    openConversations: 0,
};
const mutations = {};
const actions = {};
const getters = {
    openConversations: (state) => () => state.openConversations,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
