<template>
    <div @click="$emit('click')" class="big-button">
        <v-icon class="big-button__icon">{{ icon }}</v-icon>
        <div class="big-button__text">{{ text }}</div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            default: 'mdi-plus-box-outline',
        },
    },
};
</script>

<style scoped lang="scss">
.big-button {
    height: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    background-color: #f2f4f7;
    padding: 20px;
    border-radius: 12px;
    color: #475467;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s;

    &__icon {
        color: inherit;
    }

    &:hover {
        background-color: darken(#f2f4f7, 5%);
    }
}
</style>
