import Vuex from 'vuex';
import Vue from 'vue';
import router from '../router';
import { Buffer } from 'buffer';
import customer from './customer';
import whatsapp from './whatsapp.js';
import toolbarStates from '@/configs/toolbarStates.json';
import * as Sentry from '@sentry/vue';
import joinRooms from '../scripts/joinRooms';
import { setAuthToken } from '../plugins/api';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    //other stores
    modules: {
        // customer,
        customer,
        whatsapp,
    },
    state() {
        return {
            globalToolbar: {
                header: '',
                subheader: '',
                tabs: [],
            },
            userEmail: null,
            userOwnerAgencyID: null,
            visibilitySettings: {
                'Statusverteilung dauerhaft anzeigen': true,
                'Stakeholder anzeigen': true,
                'Anzahl Unbearbeitet anzeigen': true,
                'Anzahl alte Leads anzeigen': true,
                'Anzahl neue Leads anzeigen': true,
                'Kundenbeschreibung anzeigen': true,
                'Kundenloginstatus anzeigen': true,
            },
            logoCacheBustingNumber: 0,
            preferences: {
                view: 'table',
            },
            subuserOwnInfo: null,
            token: null,
            tokenDecoded: null,
            apiLogo: '',
            style: {
                companyName: '',
                logoURL: '',
                companyColor: '#6141db',
                companyTextColor: '#fff',
                companyStyle: 0,
                companyImprint: '',
                companyFont: '',
                betaAccess: false,
                whatsAppAccess: false,
            },
            agency: null,
            snackbarActive: false,
            snackbarMode: 'info',
            snackbarColor: undefined,
            snackbarTimeout: 2400,
            snackbarText: '',
            snackbarDescription: '',
            snackbarLink: '',
            countOpenConversations: 0,
        };
    },
    mutations: {
        SET_COUNT_OPEN_CONVERSATIONS(state, count) {
            state.countOpenConversations = count;
        },
        SET_AGENCY(state, agency) {
            state.agency = agency;
        },
        SET_TOOLBAR(state, toolbarName) {
            state.globalToolbar = toolbarStates[toolbarName];
        },
        SET_TOOLBAR_DYNAMIC(state, toolbar) {
            state.globalToolbar = toolbar;
        },

        SET_USER(state, data) {
            state.userEmail = data.email;
            state.userOwnerAgencyID = data.agencyID;
            state.logoCacheBustingNumber = Math.floor(Math.random() * 100000);
            const agencyID = state.userOwnerAgencyID;
            const url = `${process.env.BLOB_STORAGE_LOGO_URL}${agencyID}.png?chacheBusting=${state.logoCacheBustingNumber}`;
            const favicon = document.getElementById('globalfavicon');
            favicon.href = url;
        },
        log(state, msg) {
            state.snackbarMode = 'info';
            state.snackbarColor = undefined;
            state.snackbarText = msg;
            state.snackbarActive = true;
            state.snackbarTimeout = 2400;
        },
        setVisibilitySettings(state, settings) {
            state.visibilitySettings = settings;
        },

        setLogo(state, image) {
            state.apiLogo = image;
            const favicon = document.getElementById('globalfavicon');
            favicon.href = image;
        },

        logError(state, { msg, description, link }) {
            state.snackbarMode = 'error';
            state.snackbarColor = 'red accent-2';
            state.snackbarText = msg;
            state.snackbarDescription = description;
            state.snackbarLink = link || '';
            state.snackbarActive = true;
            state.snackbarTimeout = 4000;
        },
        logSuccess(state, { msg, description, link }) {
            state.snackbarMode = 'success';
            state.snackbarColor = 'green ';
            state.snackbarText = msg;
            state.snackbarLink = link || '';
            state.snackbarDescription = description;
            state.snackbarActive = true;
            state.snackbarTimeout = 3000;
        },

        closeSnackbar(state) {
            state.snackbarActive = false;
        },

        logSuccessShort(state, msg) {
            state.snackbarColor = 'green ';
            state.snackbarText = msg;
            state.snackbarActive = true;
            state.snackbarTimeout = 1500;
        },
        SET_PREFERENCES(state, preferences) {
            state.preferences = preferences;
        },

        setStyle(state, style) {
            if (!style) return;
            state.style = style;
            document.title = style.companyName + ' Portal';
        },

        logout(state) {
            state.token = '';
            localStorage.setItem('token', '');
            state.tokenDecoded = '';
            setAuthToken(null);
            router.push('/signin').catch(() => {});
        },
        logoutAndRedirect(state, redirect) {
            state.token = '';
            localStorage.setItem('token', '');
            state.tokenDecoded = '';
            setAuthToken(null);
            router.push(redirect).catch(() => {});
        },
        setToken(state, data) {
            state.token = data;

            setAuthToken(data);

            var base64Payload = data.split('.')[1];
            var payload = Buffer.from(base64Payload, 'base64');
            state.tokenDecoded = JSON.parse(payload.toString());
            joinRooms();
        },

        setView(state, view) {
            state.preferences.view = view;
            localStorage.setItem('preferences', JSON.stringify(state.preferences));
        },

        invalidateToken(state) {
            state.token = null;
            localStorage.setItem('token', '');
        },
        setCompanyFont(state, font) {
            state.style.companyFont = font;
        },
        reloadAxios(state) {
            setAuthToken(state.token);
        },
        setSubuserOwnInfo(state, subuser) {
            state.subuserOwnInfo = subuser;
        },
        initToken(state) {
            try {
                const token = JSON.parse(localStorage.getItem('token'));

                if (token !== '') {
                    state.token = token;
                    const base64Payload = token.split('.')[1];
                    const payload = Buffer.from(base64Payload, 'base64');
                    const decoded = JSON.parse(payload.toString());
                    state.tokenDecoded = decoded;
                    if (decoded.exp < Date.now() / 1000) {
                        this.invalidateToken();
                    } else {
                    }

                    Sentry.setUser({
                        id: decoded._id,
                        isAgency: decoded.agency ? true : false,
                    });
                }
            } catch {}
        },
    },
    actions: {
        async trackUser({ state, commit }) {
            const { data } = await Vue.prototype.$api.get('/tracking/startup');

            commit('SET_USER', data);
        },
        async getSubuserOwnInfo({ state, commit }) {
            if (!state.subuserOwnInfo) {
                const { data: subuser } = await Vue.prototype.$api.get('/subuser/ownInfo');
                commit('setSubuserOwnInfo', subuser);
            }
            return state.subuserOwnInfo;
        },
        async getPreferences({ state, commit }) {
            const preferences = JSON.parse(localStorage.getItem('preferences'));
            if (preferences) {
                commit('SET_PREFERENCES', preferences);
            }
            return state.preferences;
        },
        async getOrFetchAgency({ state, commit }) {
            if (state.agency) return;
            const { data: agency } = await Vue.prototype.$api.get('agency');
            commit('SET_AGENCY', agency);
            const { data: valid } = await Vue.prototype.$api.get('payment/hasValidPayment');
            if (!valid && state.tokenDecoded.agency) {
                commit('logError', { msg: 'Kein aktives Abo erkannt', description: 'Sie werden weitergeleitet' });
                return router.push('onboarding').catch(() => {});
            }
        },
        async updateAgency({ state, commit }) {
            const { data: agency } = await Vue.prototype.$api.get('agency');
            commit('SET_AGENCY', agency);
        },
    },
    getters: {
        getCountOpenConversations: (state) => state.countOpenConversations,
        isDevelopment: (state) => () => {
            //if the current host is localhost or beta.lead-table.com
            const dev = window.location.hostname === 'localhost' || window.location.hostname === 'beta.lead-table.com';
            return dev;
        },
        darkMode: (state) => () => !!state.style.companyTextColor,
        dashboardSettings: (state) => () => state.visibilitySettings,
        globalToolbar: (state) => () => state.globalToolbar,
        subuserOwnInfo: (state) => () => state.subuserOwnInfo,
        agency: (state) => () => state.agency,
        companyName: (state) => () => state.style.companyName,
        companyFont: (state) => () => state.style.companyFont,
        isAgency: (state) => () => state.tokenDecoded.agency,
        token: (state) => () => state.token,
        vissettings: (state) => () => state.visibilitySettings,
        tokenDecoded: (state) => () => state.tokenDecoded,
        style: (state) => () => state.style,
        hasBetaAccess: (state) => () => state.style.betaAccess || false,
        hasWhatsAppAccess: (state) => () => state.style.whatsAppAccess || false,
        logo: (state) => () => state.apiLogo,
        preferences: (state) => () => state.preferences,
        agencyLogo: (state) => () => {
            const agencyID = state.userOwnerAgencyID;
            if (!agencyID) return '';
            return `${process.env.BLOB_STORAGE_LOGO_URL}${agencyID}logo.png?chacheBusting=${state.logoCacheBustingNumber}`;
        },
        agencyFavicon: (state) => () => {
            const agencyID = state.userOwnerAgencyID;
            if (!agencyID) return '';
            return `${process.env.BLOB_STORAGE_LOGO_URL}${agencyID}.png?chacheBusting=${state.logoCacheBustingNumber}`;
        },
    },
});
