<template>
    <div :class="cssClass">
        <v-progress-circular :size="50" color="primary" indeterminate class="loader__spinner" />

        <div class="loader__title">
            {{ title }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
        },
        fillSpace: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        cssClass() {
            return {
                loader: true,
                'loader--fill-space': this.fillSpace,
            };
        },
    },
};
</script>

<style scoped lang="scss">
.loader {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;

    &__title {
    }

    &--fill-space {
        height: 100%;
    }
}
</style>
