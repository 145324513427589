<template>
    <div :class="cssClass">
        <v-avatar
            class="avatar-list__avatar"
            v-for="(avatar, i) in limitedAvatars"
            :style="avatarStyle(i)"
            :key="i"
            :size="iconSize"
            :color="avatar.color ?? defaults.avatarColor"
        >
            {{ avatar.name }}
        </v-avatar>

        <v-avatar v-if="!!avatarExtras" class="avatar-list__avatar-extra" :size="iconSize" color="transparent">
            +{{ avatarExtras }}
        </v-avatar>
    </div>
</template>

<script>
export default {
    props: {
        avatars: {
            type: Array,
            default: () => [],
        },
        iconSize: {
            type: Number,
            default: 32,
        },
        maxAvatars: {
            type: Number,
            default: 5,
        },
        modifier: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            defaults: {
                avatarColor: '#F2F4F7',
            },
        };
    },
    computed: {
        cssClass() {
            return {
                'avatar-list': true,
                [`avatar-list--${this.modifier}`]: !!this.modifier,
            };
        },
        limitedAvatars() {
            return this.avatars.slice(0, this.maxAvatars);
        },
        avatarExtras() {
            if (this.avatars.length <= this.maxAvatars) return 0;
            return this.avatars.length - this.maxAvatars;
        },
    },
    methods: {
        avatarStyle(i) {
            if (i === 0) return;
            return [{ marginLeft: `-${this.iconSize / 3.5}px` }];
        },
    },
};
</script>

<style lang="scss" scoped>
.avatar-list {
    $self: &;

    display: flex;

    &__avatar {
        border: 2px solid white !important;
        font-size: 12px;
    }

    &__avatar-extra {
        color: var(--Gray-600, #475467);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    &--global-pages {
        #{ $self }__avatar {
            border: none;
            box-shadow: 0 0 0 3px white;
            border: 1px solid rgba(#101828, 0.2) !important;
        }
    }
}
</style>
