import store from '../store';
import router from '../router';
import { updateCustomerSidebar } from '@/components/Sidebar/helpers/updateCustomerSidebar';
import Vue from 'vue';

const processDeleteLead = (foundCustomer, table, status) => {
    foundCustomer.totalNewLeads--;
    foundCustomer.leadsCombined--;
    foundCustomer.newLeadsLast30Days--;
    foundCustomer.lastChange = new Date().getTime();
    const statusCount = foundCustomer.statusCounts.find((s) => s.title === status);
    if (statusCount) {
        statusCount.count--;
    }

    if (table) {
        const tableStatus = table.status.find((s) => s.title === status);
        if (tableStatus) {
            tableStatus.count--;
        }
        table.leadsCount--;
    }

    if (status === 'Unbearbeitet') {
        foundCustomer.unusedLeads--;
        if (table) {
            table.leadsUnused--;
        }
    }
};

const processNewLead = (foundCustomer, table, status) => {
    foundCustomer.totalNewLeads++;
    foundCustomer.leadsCombined++;
    foundCustomer.newLeadsLast30Days++;
    foundCustomer.lastChange = new Date().getTime();
    foundCustomer.lastLead = new Date().toISOString();
    foundCustomer.statusCounts.find((s) => s.title === status).count++;

    if (table) {
        const tableStatus = table.status.find((s) => s.title === status);
        if (tableStatus) {
            tableStatus.count++;
        }
        table.leadsCount++;
    }

    if (status === 'Unbearbeitet') {
        foundCustomer.unusedLeads++;
        if (table) {
            table.leadsUnused++;
        }
    }
};

export function initSocketListeners(socket, store) {
    // when a new lead is created, fetch it and add it to the store
    socket.on('newLeadCreated', async (data) => {
        if (store.getters['customer/isTableLoaded'](data.tableID)) {
            await store.dispatch('customer/replaceLeads', [data.leadID]);
        }
        //processNewLead(foundCustomer, table, lead.status);
        await store.dispatch('customer/replaceTable', data.tableID);
        await store.dispatch('customer/replaceCustomer', data.customerID);
        if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_NEW_LEAD');
        updateCustomerSidebar(data.customerID);
    }); // done

    // when a lead is deleted, remove it from the store
    socket.on('LeadDelete', async (data) => {
        const foundCustomer = await store.getters['customer/getCustomerByID'](data.customerID);
        if (!foundCustomer) {
            return;
        }

        const table = store.getters['customer/getTableByID'](data.tableID);

        processDeleteLead(foundCustomer, table, data.status);

        store.commit('customer/UPDATE_CUSTOMER', foundCustomer);

        if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_DELETE_LEAD');

        store.commit('customer/DELETE_LEAD', data.leadID);
        store.commit('customer/REMOVE_LEAD_FROM_TABLE', data);
        updateCustomerSidebar(data.customerID);
        store.commit('logSuccess', { msg: `Lead ${data.name} gelöscht` });
    }); // done

    socket.on('deleteMultipleLeads', async (data) => {
        const { affectedLeads, affectedTables, customerID } = data;

        // TODO: update customer data
        affectedLeads.forEach((leadID) => {
            store.commit('customer/DELETE_LEAD', leadID);
            store.commit('customer/REMOVE_LEAD_FROM_TABLE', leadID);
        });

        for (const tableID of affectedTables) {
            await store.dispatch('customer/replaceTable', tableID);
        }
        await store.dispatch('customer/replaceCustomer', data.customerID);
        updateCustomerSidebar(customerID);
        store.commit('logSuccess', { msg: `${affectedLeads.length} Leads gelöscht` });
    });

    socket.on('updateLead', async (data) => {
        const { oldLead, newLead } = data;
        store.commit('customer/UPDATE_LEAD', newLead);
        await store.dispatch('customer/replaceTable', newLead.ownerTable);
        await store.dispatch('customer/replaceCustomer', newLead.ownerCustomer);
    });

    //#region tables

    // when a table is deleted, remove it from the store and update the stats
    socket.on('tableDeleted', (data) => {
        //filter table from tables in store and then log success

        store.commit('customer/DELETE_TABLE', data);
        store.commit('logSuccess', { msg: `Kampagne gelöscht` });
        if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_DELETE_TABLE');
    }); // done

    // when a table is updated, remove it from the store and add the updated version
    socket.on('updateTable', async (data) => {
        const table = await store.dispatch('customer/replaceTable', data.tableID);
        if (data.customerID) {
            updateCustomerSidebar(data.customerID);
        }
        store.commit('logSuccess', { msg: `Kampagne ${table.occupation} aktualisiert` });
    }); // done

    // when a new table is created, add it to the store and update the stats
    socket.on('newTable', async (data) => {
        const table = await store.dispatch('customer/replaceTable', data);
        await store.dispatch('customer/replaceCustomer', table.ownerCustomer);
        store.commit('logSuccess', { msg: `Neue Kampagne ${table.occupation} erstellt` });
        if (store.getters.isAgency()) store.commit('customer/UPDATE_STAT_NEW_TABLE');
    }); // done

    //#endregion tables

    socket.on('moveLeads', async (data) => {
        store.commit('customer/SET_SYNCHING', true);
        const { fromTable, toTable, fromCustomer, toCustomer, leadIDs } = data;
        await new Promise((resolve) => setTimeout(resolve, Math.random() * 100 + 10));

        await store.dispatch('customer/replaceTable', fromTable);
        await store.dispatch('customer/replaceTable', toTable);
        await store.dispatch('customer/replaceCustomer', fromCustomer);
        await store.dispatch('customer/replaceCustomer', toCustomer);
        updateCustomerSidebar(fromCustomer);
        updateCustomerSidebar(toCustomer);

        if (store.getters['customer/isTableLoaded'](fromTable) || store.getters['customer/isTableLoaded'](toTable)) {
            await store.dispatch('customer/replaceLeads', data.leadIDs);
        }
        //update sidebars

        store.commit('customer/SET_SYNCHING', false);
    });

    socket.on('multipleLeadsChanged', async (data) => {
        store.commit('customer/SET_SYNCHING', true);
        //if current route not contains tableID, only delete the leads. else replace them
        if (store.getters['customer/isTableLoaded'](data.tableID)) {
            await store.dispatch('customer/replaceLeads', data.leadIDs);
        }
        await new Promise((resolve) => setTimeout(resolve, Math.random() * 100 + 10));
        updateCustomerSidebar(data.customerID);
        await store.dispatch('customer/replaceCustomer', data.customerID);
        await store.dispatch('customer/replaceTable', data.tableID);
        store.commit('customer/SET_SYNCHING', false);
    });

    socket.on('reloadCustomer', async (customerId) => {
        await store.dispatch('customer/replaceCustomer', customerId);
        updateCustomerSidebar(customerId);
    });

    socket.on('debug', (data) => {
        console.log('DEBUG', data);
    });
}
